// src/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// Corrige o problema com ícones padrão do Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});
function Instaladores() {
  const title = "Home Page";
  const footerText = "© 2024 All rights reserved.";
  // Localização do Centro do Brasil e marcadores
  const center = [-14.2350, -51.9253]; // Centro aproximado do Brasil
  const markers = [
      {
        id: 1,
        store_name: "Lamarão Motos",
        address: "Avenida Paulo Figueiredo Barreto 475",
        city: "Lamarão",
        state: "SE",
        country: "br",
        zip: "",
        latitude: -10.8727695,
        longitude: -37.0797933,
        description: "",
      },
      {
        id: 2,
        store_name: "Wesley Motos",
        address: "Tv Gilberto sawada 19",
        address2: "Bairro: novo horizonte",
        city: "Quatro bocas Tomé Açu",
        state: "Pará",
        country: "br",
        zip: "68682000",
        latitude: -2.418038,
        longitude: -48.25073,
        description: "",
      },
      {
        id: 3,
        store_name: "G-Trike Triciclos",
        address: "Rua Espinosa 364",
        city: "Carlos Prates",
        state: "MG",
        country: "br",
        zip: "",
        latitude: -19.911434,
        longitude: -43.9597103,
        description: "",
      },
      {
        id: 4,
        store_name: "Instalador Djalma",
        address: "63 Rua Silvino Marcolino da Silva",
        city: "",
        state: "PB",
        country: "br",
        zip: "58270-000",
        latitude: -6.8477725,
        longitude: -35.3851346,
        description: "",
      },
      {
        id: 5,
        store_name: "Pilo Motos",
        address: "R. Vicente Francisco Safanelli",
        city: "Guaramirim",
        state: "SC",
        country: "",
        zip: "89270-000",
        latitude: -26.4752,
        longitude: -49.0115,
        description: "",
      },
      {
        id: 6,
        store_name: "Sonic Motos",
        address: "24 Rua Havaí",
        city: "Mangueiras",
        state: "PA",
        country: "br",
        zip: "",
        latitude: -1.1543227,
        longitude: -48.4618036,
        description: "",
      },
      {
        id: 7,
        store_name: "Tita Racing",
        address: "Rua major Lúcio Meireles 1870",
        city: "Camaquã",
        state: "RS",
        country: "br",
        zip: "96785148",
        latitude: -30.866451,
        longitude: -51.80119,
        description: "",
      },
      {
        id: 8,
        store_name: "Mothomaz",
        address: "Avenida Marechal Castelo Branco número 74",
        city: "fazendinha",
        state: "RJ",
        country: "br",
        zip: "",
        latitude: -22.859128,
        longitude: -42.323374,
        description: "",
      },
      {
        id: 9,
        store_name: "Natan Instalação",
        address: "509 Rua Cel Ramalho",
        city: "Centro",
        state: "MG",
        country: "br",
        zip: "37578-000",
        latitude: -22.436021,
        longitude: -46.3524788,
        description: "",
      },
      {
        id: 10,
        store_name: "BIKE WORLD",
        address: "11 Rua Jose Correia de Andrade",
        city: "Natal",
        state: "RN",
        country: "br",
        zip: "59258-000",
        latitude: -6.2797591,
        longitude: -35.4956244,
        description: "",
      },
      {
        id: 11,
        store_name: "bieffemotos",
        address: "Rua. Biotônico N 901",
        city: "Suzano",
        state: "SP",
        country: "br",
        zip: "08615-000",
        latitude: -23.553223,
        longitude: -46.291438,
        description: "",
      },
      {
        id: 12,
        store_name: "B&R Serviços",
        address: "Rua Dálias Lilás 361",
        city: "Salvador",
        state: "Bahia",
        country: "br",
        zip: "41.502-420",
        latitude: -12.90048,
        longitude: -38.35615,
        description: "",
      },
      {
        id: 13,
        store_name: "Oficina Alfa Omega",
        address: "Rua general Cunha matos qd26 lt09",
        city: "",
        state: "",
        country: "",
        zip: "74.323-250",
        latitude: -16.700439,
        longitude: -49.31646,
        description: "",
      },
      {
        id: 14,
        store_name: "Robin Motos",
        address: "Avenida planice quadra 01 lote 05 residencial morada dos sonhos Goiânia Goiás",
        city: "Goiania",
        state: "GO",
        country: "br",
        zip: "74690248",
        latitude: -16.5999235,
        longitude: -49.2536455,
        description: "",
      },
      {
        id: 15,
        store_name: "Instalador JMS Autocar",
        address: "Rua sr12 q15 l22 n19",
        city: "Recanto das minas gerais",
        state: "GO",
        country: "",
        zip: "74785145",
        latitude: -16.641607,
        longitude: -49.193504,
        description: "",
      },
      {
        id: 16,
        store_name: "Alfacar Assessórios automotivo",
        address: "Rua Clodomiro Clóvis Cunha 647",
        city: "Piumhi",
        state: "MG",
        country: "",
        zip: "37925-000",
        latitude: -20.47185,
        longitude: -45.949063,
        description: "",
      },
      {
        id: 17,
        store_name: "Taga Motos",
        address: "Av Governador Magalhães Pinto 1110",
        city: "Divinópolis",
        state: "MG",
        country: "br",
        zip: "35-500-220",
        latitude: -20.135444,
        longitude: -44.873333,
        description: "",
      },
      {
        id: 18,
        store_name: "ASPHALT MOTORCYCLES",
        address: "R. das Camélias, 217B - Jardim Montreal",
        city: "Ibirité",
        state: "MG",
        country: "br",
        zip: "32419-459",
        latitude: -20.0122076,
        longitude: -44.1214519,
        description: "",
      },
      {
        id: 19,
        store_name: "WN Moto Performance",
        address: "Rua Duarte de abreu, 125 Mariano Procópio",
        city: "Juiz de Fora",
        state: "MG",
        country: "br",
        zip: "36080-070",
        latitude: -21.745964,
        longitude: -43.36293,
        description: "",
      },
      {
        id: 20,
        store_name: "Megabox",
        address: "Rua Nerval de golveia 451",
        city: "Rio de janeiro",
        state: "RJ",
        country: "br",
        zip: "21311110",
        latitude: -22.882585,
        longitude: -43.328667,
        description: "",
      },
      {
        id: 21,
        store_name: "DANIEL SUPER MOTOZ",
        address: "Av. Irene Lopes Sodré 6474",
        city: "Niteroi",
        state: "RJ",
        country: "br",
        zip: "24346040",
        latitude: -22.926482,
        longitude: -43.007496,
        description: "",
      },
      {
        id: 22,
        store_name: "Cleoney Super Motos",
        address: "Avenida central em frente posto leandrinho",
        city: "Uruará",
        state: "PA",
        country: "",
        zip: "68140-000",
        latitude: -3.721953,
        longitude: -53.735251,
        description: "",
      },
      {
        id: 23,
        store_name: "Leal Moto Racing",
        address: "",
        city: "Gravatal",
        state: "SC",
        country: "",
        zip: "36422120",
        latitude: -28.341295,
        longitude: -49.031879,
        description: "",
      },
      {
        id: 24,
        store_name: "Koala Leds",
        address: "Rua : Trairir numero 200",
        city: "Natal",
        state: "RN",
        country: "br",
        zip: "",
        latitude: -5.8162,
        longitude: -35.2504,
        description: "",
      },
      {
        id: 25,
        store_name: "DNA Motos",
        address: "Rua Nely vieira de Lacerda número 15",
        city: "Campina grande",
        state: "PB",
        country: "br",
        zip: "58415505",
        latitude: -7.255311,
        longitude: -35.907298,
        description: "",
      },
      {
        id: 26,
        store_name: "Ty Reges",
        address: "Rua João arcanjo duarte número 238",
        city: "Catalão",
        state: "GO",
        country: "br",
        zip: "75700000",
        latitude: -18.147809,
        longitude: -47.927052,
        description: "",
      },
      {
        id: 27,
        store_name: "Robo Motos",
        address: "Rua cento e sete Bairro caetes 1",
        city: "Abreu e Lima",
        state: "Pernambuco",
        country: "br",
        zip: "53530755",
        latitude: -7.913914,
        longitude: -34.914355,
        description: "",
      },
      {
        id: 28,
        store_name: "West Auto",
        address: "Pode colocar avenida piatã/cabralia sn",
        city: "Piatã",
        state: "BA",
        country: "br",
        zip: "46768-000",
        latitude: -13.0488747,
        longitude: -41.8835514,
        description: "",
      },
      {
        id: 29,
        store_name: "Motos Gaspar",
        address: "Rua. Antônio Pereira 304",
        city: "Florânia",
        state: "RN",
        country: "br",
        zip: "59335000",
        latitude: -6.125277,
        longitude: -36.817292,
        description: "",
      },
      {
        id: 30,
        store_name: "B2 Custom",
        address: "31 Rua Andrea Fabiano de Carvàlhò",
        city: "Campo dos Alemães",
        state: "SP",
        country: "br",
        zip: "12239-160",
        latitude: -23.26864,
        longitude: -45.900731,
        description: "",
      },
      {
        id: 31,
        store_name: "Academia do Pneu",
        address: "340 Estrada Manoel de Sá",
        city: "Parque Martinho",
        state: "RJ",
        country: "br",
        zip: "26185-423",
        latitude: -22.7072306,
        longitude: -43.3352183,
        description: "",
      },
      {
        id: 32,
        store_name: "4mxmotos",
        address: "390 Rua Getúlio Borges",
        city: "Vila Garcia",
        state: "MG",
        country: "br",
        zip: "38703-176",
        latitude: -18.5785929,
        longitude: -46.5078296,
        description: "",
      },
      {
        id: 33,
        store_name: "Mateus Motos",
        address: "983 Rua das Palmeiras",
        city: "Quedas do Iguaçu",
        state: "PR",
        country: "br",
        zip: "85460-000",
        latitude: -25.4530548,
        longitude: -52.9057058,
        description: "",
      },
      {
        id: 34,
        store_name: "Rafael",
        address: "Rua queimados",
        city: "rio negrinho",
        state: "SC",
        country: "br",
        zip: "89295000",
        latitude: -26.398363,
        longitude: -49.564232,
        description: "",
      },
      {
        id: 35,
        store_name: "Garagem do helk",
        address: "48 Rua A Vinte e Cinco",
        city: "Conjunto Marcos Freire II",
        state: "SE",
        country: "br",
        zip: "49160-000",
        latitude: -10.8575613,
        longitude: -37.0579289,
        description: "",
      },
      {
        id: 36,
        store_name: "Oficina Cabeça Motos",
        address: "327 Estrada da Gávea",
        city: "Gávea",
        state: "RJ",
        country: "br",
        zip: "22451-265",
        latitude: -22.9875207,
        longitude: -43.2453623,
        description: "",
      },
      {
        id: 37,
        store_name: "jlmotos",
        address: "Avenida Mauro bizi número 192",
        city: "Colatina",
        state: "ES",
        country: "br",
        zip: "29705232",
        latitude: -19.492547,
        longitude: -40.665792,
        description: "",
      },
      {
        id: 38,
        store_name: "DANIEL SANTIAGO DE SOUZA",
        address: "AV. IRENE LOPES SODRE 6474, LOJA 101",
        city: "Niterói",
        state: "RJ",
        country: "br",
        zip: "24346040",
        latitude: -43.007472,
        longitude: -22.926486,
        description: "",
      }
  ];
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Welcome to the Home Page of our application." />
      </Helmet>
      <Header title={title} />
      <main>
      <MapContainer
          center={center}
          zoom={5}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {markers.map(marker => (
            <Marker key={marker.id} position={[marker.latitude, marker.longitude]}>
              <Popup>
                <strong>{marker.store_name}</strong><br />
                {marker.address}<br />
                {marker.city}, {marker.state}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Instaladores;
