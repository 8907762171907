// src/Home.js

import React,{ useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import mercadoLivre from '../../assets/imagens/mercado-livre-88.png'
import valorMercadoLivre from '../../assets/imagens/valorMercadoLivre.jpg'

import './styles.css';

const Modal = ({ show, handleClose, handleYes,link }) => {
  if (!show) return null; // Não renderiza o modal se o estado 'show' for falso

  return (
    <div className="modal-overlay">
      <div className="modal">
        <span onClick={handleClose}>X</span>
        <h2>Tem certeza?</h2>
        <img src={valorMercadoLivre} alt="Taxa do mercado livre"/>
        <p>O Mercado Livre cobra uma comissão de cerca de 100 reais por venda, por este motivo os valores da compra diretamente no site oficial ficam mais em conta.
        <br/><br/>
        Deseja prosseguir com a compra pelo mercado livre?
        </p>
        <div className="modal-buttons">
          <button onClick={handleYes}>
            Sim
          </button>
          <button onClick={handleClose}>Não, vou comprar aqui com desconto</button>
        </div>
      </div>
    </div>
  );
};
function InformacoesProduto() {
  
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleYes = () => {
    window.open(data.urlMercadoLivre, '_blank', 'noopener,noreferrer');
    closeModal(); // Fecha o modal após clicar em "Sim"
    
    window.gtag('event', 'click', {
      'event_category': data.nome,
      'event_label': 'Mercado Livre',
      'value': 1
    });
    
  };



  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Para redirecionar

  // Usando useState para armazenar o valor do input
  const [qtdCarrinho, setQtdCarrinho] = useState(1);

  // Função que será chamada sempre que o valor do input mudar
  const qtdCarrinhoChange = (event) => {
    setQtdCarrinho(event.target.value);  // Atualiza o estado com o valor do input
  };

   // Usando useState para armazenar o valor do input
   const [modelo, setModelo] = useState('');

   // Função que será chamada sempre que o valor do input mudar
   const modeloChange = (event) => {
    setModelo(event.target.value);  // Atualiza o estado com o valor do input
   };

  // Usando useState para armazenar o valor do input
  const [frente, setFrente] = useState('');

  // Função que será chamada sempre que o valor do input mudar
  const frenteChange = (event) => {
    setFrente(event.target.value);  // Atualiza o estado com o valor do input
  };
  // Usando useState para armazenar o valor do input
  const [sensor, setSensor] = useState('');

  // Função que será chamada sempre que o valor do input mudar
  const sensorChange = (event) => {
    setSensor(event.target.value);  // Atualiza o estado com o valor do input
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var objetoCarrinho = undefined;
    try{
      objetoCarrinho = JSON.parse(localStorage.getItem("carrinho"));
    }
    catch (error) {
      console.log("falha no carrinho");
    }
    data.quantidade = qtdCarrinho;
    data.frente = frente;
    data.modelo = modelo;
    data.sensor = sensor;
    if(objetoCarrinho){
      objetoCarrinho.push(data);
      localStorage.setItem("carrinho",JSON.stringify(objetoCarrinho));
      var quantidadeTotalCarrinho = 0;
      objetoCarrinho.forEach(
        function(p){
          quantidadeTotalCarrinho += parseInt(p.quantidade);
        }
      );
      
      localStorage.setItem("quantidadeCarrinho",quantidadeTotalCarrinho);
    }
    else{
      localStorage.setItem("carrinho",JSON.stringify([data]));
      localStorage.setItem("quantidadeCarrinho",qtdCarrinho);
    }
    navigate('/carrinho');
    window.gtag('event', 'click', {
      'event_category': data.nome,
      'event_label': 'Carrinho',
      'value': 1
    });
    window.gtag('event', 'add_to_cart', {
      currency: 'BRL',
      value: data.valorDesconto, // Valor do produto
      items: [
        {
          item_id: data.id, // ID do produto
          item_name: data.nome, // Nome do produto
          item_category: 'Painel', // Categoria do produto
          price: data.valorDesconto, // Preço
        }
      ]
    });
  };

  useEffect(() => {
    // Fazendo a chamada para a API
    fetch('https://api.streetfightermotoparts.com.br/api/produtos/' + id)
      .then(response => response.json()) // Converte a resposta para JSON
      .then(resposta => {
        if (!resposta.length) {
          navigate('/404'); // Redireciona para a página 404 se não houver dados
        } else {
          setData(resposta[0]); // Armazena os dados da resposta
          window.gtag('event', 'view_item', {
            currency: 'BRL', // Moeda usada
            value: resposta[0].valorDesconto, // Valor do produto
            items: [
              {
                item_id: resposta[0].id, // ID do produto
                item_name: resposta[0].nome, // Nome do produto
                item_category: 'Painel', // Categoria do produto
                price: resposta[0].valorDesconto, // Preço
              }
            ]
          });
        }
        setLoading(false); // Atualiza o estado para indicar que o carregamento terminou
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      });
  }, [id, navigate]); // Incluindo 'id' e 'navigate' no array de dependências

  if (loading) {
    return <div>Carregando...</div>;
  }
  const title = data.nome +" - StreetFighter";
  const footerText = "© 2024 All rights reserved.";
  function moeda(valor){
    return new Intl.NumberFormat('pt-BR', { 
      style: 'currency', 
      currency: 'BRL' 
    }).format(valor)
  }

  const jsonLd = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": data.nome,
    "image": "https://streetfightermotoparts.com.br"+data.fotoCapa,
    "description":  data.descricao,
    "sku": data.identificador,
    "mpn": data.id,
    "brand": {
      "@type": "Brand",
      "name": "Street Fighter Moto Parts"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://streetfightermotoparts.com.br/produto/"+id,
      "priceCurrency": "BRL",
      "price": data.valorDesconto,
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-10-17T00:00:00+00:00",
      "hasMerchantReturnPolicy": {
        "@type": "MerchantReturnPolicy",
        "url": "https://streetfightermotoparts.com.br/politica-de-devolucao",
        "merchantReturnDays": 30,
        "returnMethod": "ReturnByMail",
        "returnFees": "FreeReturn"
      },
      "shippingDetails": {
        "@type": "OfferShippingDetails",
        "shippingRate": {
          "@type": "MonetaryAmount",
          "value": "0.00",
          "currency": "BRL"
        },
        "deliveryTime": {
          "@type": "ShippingDeliveryTime",
          "handlingTime": {
            "@type": "QuantitativeValue",
            "minValue": 1,
            "maxValue": 1,
            "unitCode": "DAY"
          },
          "transitTime": {
            "@type": "QuantitativeValue",
            "minValue": 15,
            "maxValue": 15,
            "unitCode": "DAY"
          }
        },
        "shippingDestination": {
          "@type": "DefinedRegion",
          "addressCountry": "BR"
        }
      }
    }
    /*,
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": "23"
    }*/
  };
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={data.descricao} />
        <link rel="canonical" href={"https://streetfightermotoparts.com.br/produto/"+id} />
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>
      <Header title={title} />
      <main className='cardCarrinho'>
        <div className='tituloCarrinho'>
        </div>
        <div className='informacoesProduto'>
            <div className='colunaInformacoesProduto'>
              
              <img className="imagemProdutoInformacaoes" src={data.fotoCapa} alt={"Imagem do "+data.nome}/>
            </div>
            <div className='coluna2InformacoesProduto'>
              <div>
                <h1>{data.nome}</h1>
                <p className='descricaoProduto' dangerouslySetInnerHTML={{ __html: data.descricao.replace(/\n/g, '<br />') }}/>
                {/*data.descricao.split('\n').map((linha, index) => (
                  <React.Fragment key={index}>
                    {linha}
                    <br />
                  </React.Fragment>
                ))*/}
                <div className='informacaoValor'>
                  <h1>{moeda(data.valorDesconto)} <span>No pix</span></h1>
                  <span>ou {moeda(data.valor)} em até 12x sem juros</span>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='formularioProduto'>
                    Informe o modelo da moto para instalação
                    <input type="text" placeholder='Honda Start 160' className='form-control' value={modelo} onChange={modeloChange} required/>
                  </div>
                  <div className='formularioProduto'>
                    Frente original?
                    <select className='form-select' value={frente} onChange={frenteChange} required>
                      <option value={""}>
                        
                      </option>
                      <option value={"Frente original"}>
                        Sim
                      </option>
                      <option value={"Frente modificada"}>
                        Não
                      </option>
                    </select>
                  </div>
                  <div className='formularioProduto' required>
                    Sensor?
                    <select className='form-select' value={sensor} onChange={sensorChange}>
                      <option value={""}>
                        
                      </option>
                      <option value={"Tamborzinho"}>
                        Tamborzinho: Ideal para motos com cabo de velocidade 
                      </option>
                      <option value={"Magnético"}>
                        Magnético: Compatível com qualquer veículo
                      </option>
                      <option value={"Caracol"}>
                        Caracol: Ideal para Twister, Falcon, Cb 300 e CB 500
                      </option>
                    </select>
                  </div>
                  {data.status ? (
                  <span><div className="add_cart_area">
                    <div className="addToCardContainer">
                      <input className="addqty" type="text" value={qtdCarrinho} onChange={qtdCarrinhoChange}/>
                      <button type="submit" className="addtocartbutton">Adicionar ao carrinho</button>
                      </div>											
                  </div>
                  <div className='botaoML'>
                    <button type="button" onClick={openModal}><img src={mercadoLivre} alt="Logo mercado livre"/></button>
                  </div></span>):(<div className='indisponivel'>Indisponível</div>)}
                </form>
              </div>
            </div>
        </div>
      </main>
      <Footer footerText={footerText} />
      <Modal show={showModal} handleClose={closeModal} handleYes={handleYes} link={data.urlMercadoLivre} />
    </div>
  );
}

export default InformacoesProduto;
